import { cloneElement, memo, type JSX } from 'react';

type HorizontalListVirtualItemProps = {
  index: number;
  size: number;
  start: number;
  children: JSX.Element;
};

/**
 * This component aims to build style property and pass to the child component
 * Wrapped by a memo() to avoid rerendering by tanstack virtual
 * @param HorizontalListVirtualItemProps
 * @returns JSX
 */
const HorizontalListVirtualItem = ({
  index,
  size,
  start,
  children,
}: HorizontalListVirtualItemProps): JSX.Element => {
  return cloneElement(children, {
    index,
    style: {
      position: 'absolute',
      width: `${size}px`,
      transform: `translateX(${start}px)`,
    },
  });
};

export default memo(HorizontalListVirtualItem);
