import { Binder, type StoreBinder } from '@dce-front/one-navigation';
import { Ratio } from '@dce-front/onewebapp-utils';
import { Carousel } from '@dce-front/sharedcomponent';
import classNames from 'classnames';
import type { JSX } from 'react';
import { memo } from 'react';
import { useBinderMiddleware } from '../../helpers/hooks/useBinderMiddleware';
import { FocusManager } from '../../helpers/oneNavigation/FocusManager';
import { getMiddlewareStrate } from '../../helpers/oneNavigation/middleware';
import { useTranslation } from '../../lang';
import type {
  ContentStrateV5,
  DisplayParameters,
} from '../../templates/LandingV5/data/formatter';
import CarouselVirtual from '../CarouselVirtual/CarouselVirtual';
import styles from './CarrouselTemplate.css';
import CarrouselTemplateItem from './CarrouselTemplateItem';

type MakeCarouselItemsParameter = {
  contents: ContentStrateV5[];
  ratio: Ratio;
  hasBorderRadius: boolean;
  isDesktopOnly?: boolean;
  isMobileOnly?: boolean;
};

const makeCarouselItems = ({
  contents,
  ratio,
  hasBorderRadius,
  isDesktopOnly = false,
  isMobileOnly = false,
}: MakeCarouselItemsParameter) => {
  const ItemComponent = $_BUILD_RENDERMODE_CSR ? 'li' : 'div';

  return contents.map((content, index) => (
    <ItemComponent
      className={classNames(
        styles.carrouselTemplate__cover,
        styles[`carrouselTemplate__cover--${ratio}`],
        'carrousel',
      )}
      key={content.contentID || index}
      data-index={index}
    >
      <CarrouselTemplateItem
        borderRadius={hasBorderRadius}
        content={content}
        isDesktopOnly={isDesktopOnly}
        isMobileOnly={isMobileOnly}
        ratio={ratio}
      />
    </ItemComponent>
  ));
};

export type CarrouselTemplateProps = {
  contents: ContentStrateV5[];
  displayParameters?: DisplayParameters;
  focusManager?: FocusManager;
  binderId?: StoreBinder['id'];
};

function CarrouselTemplate({
  contents = [],
  displayParameters: { imageSize = 'normal' } = {},
  focusManager,
  binderId,
}: CarrouselTemplateProps): JSX.Element | null {
  const { t } = useTranslation();

  const middlewareStrate = useBinderMiddleware(getMiddlewareStrate);

  const isImageSizeLarge = imageSize === 'large';
  const mobileRatio = isImageSizeLarge ? Ratio.Ratio43 : Ratio.Ratio169;
  const tabletRatio = isImageSizeLarge ? Ratio.Ratio169 : Ratio.Ratio166;

  if (!contents.length) {
    return null;
  }

  const carouselItemsParams = {
    contents,
    hasBorderRadius: isImageSizeLarge,
  } satisfies Omit<MakeCarouselItemsParameter, 'ratio'>;

  const mobileItems = makeCarouselItems({
    ...carouselItemsParams,
    ratio: mobileRatio,
    isMobileOnly: true,
  });
  const tabletItems = makeCarouselItems({
    ...carouselItemsParams,
    ratio: tabletRatio,
    isDesktopOnly: true,
  });

  return (
    <Binder binderId={binderId} middleware={middlewareStrate}>
      {$_BUILD_RENDERMODE_CSR ? (
        <CarouselVirtual
          ratio={tabletRatio}
          imageSize={imageSize}
          items={tabletItems}
          focusManager={focusManager}
        />
      ) : (
        <Carousel
          imageSize={imageSize}
          isCarrousel
          isTvDevice={false}
          labels={{
            next: t('HorizontalPaging.next'),
            previous: t('HorizontalPaging.previous'),
          }}
          mobileItems={mobileItems}
          mobileRatio={mobileRatio}
          ratio={tabletRatio}
          showControls
        >
          {tabletItems}
        </Carousel>
      )}
    </Binder>
  );
}

export default memo(CarrouselTemplate);
